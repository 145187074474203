import { useEffect, useState } from "react"

export default function ScrollBar() {
  const [scrollBarWidth, setScrollBarWidth] = useState('0%')

  const handleScrollbar = () => {
    const mainElement = document.querySelector('main')
    if (mainElement) {
      const fullHeight = mainElement.scrollHeight
      const currentScroll = window.scrollY
      const windowHeight = window.innerHeight
      const scrollPercentage = (currentScroll / (fullHeight - windowHeight)) * 100
      const clampedPercentage = Math.min(100, Math.max(0, scrollPercentage))
      setScrollBarWidth(`${clampedPercentage}%`)
    }
  }

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener('scroll', handleScrollbar)

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScrollbar)
    }
  }, [])

  return (
    <div id="scrollBar" style={{ width: scrollBarWidth }}></div>
  )
}