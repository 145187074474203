import { useRef, useEffect, useState } from "react"
import Home from "./pages/Home"
import About from "./pages/About"
import Contactme from "./pages/Contactme"
import ScrollBar from "./layout/ScrollBar"

function App() {
const appRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  )

  useEffect(() => {
    const landscape = window.matchMedia("(orientation: landscape)")

    const handleOrientationChange = () => {
      setIsLandscape(landscape.matches)
      if (landscape.matches) {
        setIsLandscape(true); // Set to true to render the message
        // orientation: landscape
        // Check the width condition here
        if (window.innerHeight > 500) {
          //console.log(window.innerWidth)
          setIsLandscape(false); // Set to false to render the app
        }
      } else {
        //orientation: portrait
        setIsLandscape(false); // Set to false to render the app
      }
    }

    // Initial check
    handleOrientationChange()

    // Attach event listener
    landscape.addEventListener("change", handleOrientationChange)

    // Cleanup on component unmount
    return () => {
      landscape.removeEventListener("change", handleOrientationChange)
    }
  }, [])

  return (
    <>
      {isLandscape ? (
        <div id="landscapeMessage">
          <p>Turn your device 90°</p>
        </div>
        )
     : 
        (
        <main ref={appRef}>
          <ScrollBar/>
          <Home />
          <About />
          <Contactme />
        </main>
      )}
    </>
  )
}

export default App
