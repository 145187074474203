import { useEffect, useState } from 'react'

const TouchDeviceChecker = ({ onIsTouchDeviceChange }) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    const checkTouchDevice = () => {
      setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)

      // Callback to send the touch device state to the parent component
      if (onIsTouchDeviceChange) {
        onIsTouchDeviceChange(isTouchDevice)
      }
    }

    // Initial check
    checkTouchDevice()

    // Event listener for changes in touch device status
    window.addEventListener('touchstart', checkTouchDevice)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('touchstart', checkTouchDevice)
    }
  }, [onIsTouchDeviceChange, isTouchDevice])

  // You can render something here if needed, or just return null
  return null
}

export default TouchDeviceChecker