import { I, J, L, O, S, T, Z } from './tetrominos'

const tetris = (gameOn, gridOn, paused, updateScore, hardcore, touchDevice) => {
    let ROW, COL, SQ, canvas, context, board, VACANT, p, dropStart, gameOver, score, isBoardCreated, animationFrameId, speed, 
    areaLeft, areaRight, areaDown, areaRotate, speedIncrement

    let isGameOn = gameOn
    let isGridOn = gridOn
    let isPaused = paused
    let isHardcore = hardcore
    let isTouchDevice = touchDevice

    const init = () => {
        canvas = document.getElementById('tetris_div')
        context = canvas.getContext('2d')
        
        if (isTouchDevice) {
            areaLeft = document.getElementById('areaLeft')
            areaRight = document.getElementById('areaRight')
            areaDown = document.getElementById('areaDown')
            areaRotate = document.getElementById('areaRotate')

            areaLeft.addEventListener('touchstart', (e) => {
                e.preventDefault()
                p.moveLeft()
                dropStart = Date.now()
            })
            areaRight.addEventListener('touchstart', (e) => {
                e.preventDefault()
                p.moveRight()
                dropStart = Date.now()
            })
            areaDown.addEventListener('touchstart', (e) => {
                e.preventDefault()
                p.moveDown()
            }) 
            areaRotate.addEventListener('touchstart', (e) => {
                e.preventDefault()
                p.rotate()
                dropStart = Date.now()
            })
        }

        const deviceWidth = document.documentElement.clientWidth
        
        if (deviceWidth <= '500') {
            // this is a small screen so the cells are bigger
            SQ = isHardcore? 35 : 25 // size of a cell 50px/50px
            
        }
        else if (deviceWidth <= '1000') {
            //this is a medium screen the cell is smaller 
            SQ = isHardcore? 50 : 35 // size of a cell 50px/50px
        }
        else {
            //this is a large screen the cells are smaller
            SQ = isHardcore? 75 : 50 // size of a cell 50px/50px
        }

        VACANT = '#202020' // empty cell is the same color as the bg

        speed = isHardcore? 250 : 500

        // Initialize the board and draw it
        updateBoard()
        drawBoard()

        console.log('init game')

        // Add a resize event listener to update canvas and board on window resize
        window.addEventListener('resize', () => {
            updateBoard()
            drawBoard()
            isBoardCreated = true
        })
    }

    const updateBoard = () => {
        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        // Calculate ROW and COL based on the window dimensions
        ROW = Math.floor(windowHeight / SQ)
        COL = Math.floor(windowWidth / SQ) // FOR THE ENTIRE SCREEN WIDTH
        //COL = 10 // FOR A DEFINED NUMBER OF COL



        // Set the canvas size based on ROW and COL
        canvas.width = COL * SQ
        canvas.height = ROW * SQ

        // Initialize the board array
        board = []
        for (let r = 0; r < ROW; r++) {
            board[r] = []
            for (let c = 0; c < COL; c++) {
                board[r][c] = VACANT
            }
        }
    }

    // draw a square
    const drawSquare = (x, y, color) => {
        context.fillStyle = color
        context.fillRect(x * SQ, y * SQ, SQ, SQ)

        // draw the grid
        if (isGridOn && !isBoardCreated) {
            //context.strokeStyle = 'rgba(255, 255, 255, 0.1)' // Set stroke color with alpha
            context.strokeStyle = '#676767'
            context.lineWidth = .125 // Set line width for the grid
            context.strokeRect(x * SQ, y * SQ, SQ, SQ)
        }
        

        // conditionally draw the bottom border        
        /*context.strokeStyle = '#EB5757'
        if (y === ROW - 1) {
            context.beginPath()
            context.moveTo(x * SQ, (y + 1) * SQ)
            context.lineTo((x + 1) * SQ, (y + 1) * SQ)
            context.stroke()
        }*/
    }

    // draw the entire board
    const drawBoard = () => {
        for (let r = 0; r < ROW; r++) {
            for (let c = 0; c < COL; c++) {
                drawSquare(c, r, board[r][c])
            }
        }
    }

    //the pieces and their colors
    const PIECES = [
        [Z, isHardcore ? '#676767' : '#EB5757'], //#F0F0F0
        [S, isHardcore ? '#676767' : '#EBD357'], //#D7D7D7
        [T, isHardcore ? '#676767' : '#EB9557'], //#B9B9B9
        [O, isHardcore ? '#676767' : '#57EB80'], //#909090
        [L, isHardcore ? '#676767' : '#B357EB'], //#676767
        [J, isHardcore ? '#676767' : '#57D9EB'], //#424242
        [I, isHardcore ? '#676767' : '#5792EB']  //#262626
      ]

    //generate random pieces
    function randomPiece() {
        let r = Math.floor(Math.random() * PIECES.length) //gives a number between 0 and 6
        //let randomN = r 
        return new Piece( PIECES[r][0], PIECES[r][1])// first index is the shape and the second index is the color
    }

    //create a class Piece
    function Piece(tetromino, color) {
        this.tetromino = tetromino.shape
        this.color = color

        this.tetrominoN = 0 //we start from the first pattern
        this.activeTetromino = this.tetromino[this.tetrominoN]

        //we need to control the pieces
        this.x = Math.floor((canvas.width /SQ) / 2) - 2 //center based on the window width. -2 cells to center the piece 
        //this.x = 10 //for testing
        this.y = -2  //normal behavior
    }

    /* ALL FUNCTIONS FOR CLASS BELOW ⬇︎ */
    //fill function 
    Piece.prototype.fill = function(color) {
        for (let r = 0; r < this.activeTetromino.length; r++) {
            for (let c = 0; c < this.activeTetromino[r].length; c++) {
                //we only draw occupied squares
                if (this.activeTetromino[r][c]) {
                    drawSquare(this.x + c, this.y + r, color);
                }
            }
        }
    }

    //draw a piece on the board
    Piece.prototype.draw = function() {
        this.fill(this.color)
    }

    //undraw a piece on the board
    Piece.prototype.unDraw = function() {
        this.fill(VACANT)
    }

    // move shape down
    Piece.prototype.moveDown = function() {
        if (!this.collision(0,1, this.activeTetromino)) {
            this.unDraw()
            this.y++
            this.draw()
        } else {
            this.lock()
            if (isGameOn) {
                //new shape created
                p = randomPiece()
            }
        }
    }

    // move shape right
    Piece.prototype.moveRight = function() {
        if (!this.collision(1,0, this.activeTetromino)) {
            this.unDraw()
            this.x++
            this.draw()
        }
    }

    // move shape left
    Piece.prototype.moveLeft = function() {
        if (!this.collision(-1,0, this.activeTetromino)) {
            this.unDraw()
            this.x--
            this.draw()
        }
    }

    // rotate shape
    Piece.prototype.rotate = function() {
        let nextPattern = this.tetromino[(this.tetrominoN + 1) % this.tetromino.length]
        let kick = 0

        if (this.collision(0,0, nextPattern)) {
            //split the screen in two halves
            if(this.x > COL/2) { // right wall
                kick = -1 // we need to move the piece to the left
            } else { // left wall
                kick = 1 // we need to move the piece to the right
            }
        }
        if (!this.collision(kick,0, nextPattern)) {
            this.unDraw()
            this.x += kick
            this.tetrominoN = (this.tetrominoN + 1) % this.tetromino.length // (0+1) % 4 = 1
            this.activeTetromino = this.tetromino[this.tetrominoN]
            this.draw()
        }   
    }

    // lock the shape when they touch the ground
    Piece.prototype.lock = function () {
        for (let r = 0; r < this.activeTetromino.length; r++) {
            for (let c = 0; c < this.activeTetromino[r].length; c++) {
                // we skip the vacant cells
                if (!this.activeTetromino[r][c]) {
                    continue
                }
                // pieces to lock on top = game over
                if (this.y + r < 0) {
                    gameOver = true
                    clearBoardRandomlyWithDelay()
                    console.log('game is over')
                    break
                }
                //we lock the piece
                board[this.y + r][this.x + c] = this.color
            }
        }
        // remove full rows
        for(let r = 0; r < ROW; r++){
            let isRowFull = true;
            for( let c = 0; c < COL; c++){
                isRowFull = isRowFull && (board[r][c] !== VACANT);
            }
            if(isRowFull){
                // if the row is full
                // we move down all the rows above it
                for (let y = r; y > 1; y--){
                    for(let c = 0; c < COL; c++){
                        board[y][c] = board[y-1][c];
                    }
                }
                // the top row board[0][..] has no row above it
                for (let c = 0; c < COL; c++){
                    board[0][c] = VACANT;
                }
                // increment the score
                score += 10;
                if (updateScore) {
                    updateScore(score)
                    if (score >= speedIncrement) {
                        speed -= 10
                        speedIncrement += 100
                    }
                    
                }
            }
        }
        // update the board
        drawBoard()
    }

    // detect collision
    Piece.prototype.collision = function(x,y,piece) {
        for(let r = 0; r < piece.length; r++){
            for(let c = 0; c < piece.length; c++){
                // if the square is empty, we skip it
                if(!piece[r][c]){
                    continue;
                }
                //coordinats of the piece after movement
                let newX = this.x + c + x
                let newY = this.y + r + y

                // conditions
                if (newX < 0 || newX >= COL || newY >= ROW) {
                    return true
                }
                // skip newY < 0 board[-1] crashes the game
                if (newY < 0) {
                    continue
                }
                // check if there is locked piece on the board
                if (board[newY][newX] !== VACANT) {
                    return true
                }
            }
        }
        return false
    }

    // USER CONTROLS
    document.addEventListener('keydown', CONTROLS)
    function CONTROLS(e) {
        e.preventDefault()
        switch (e.key) {
            case "ArrowLeft":
                p.moveLeft()
                dropStart = Date.now()
            break
            case "ArrowRight":
                p.moveRight()
                dropStart = Date.now()
            break
            case "ArrowUp":
                p.rotate()
                dropStart = Date.now()
            break
            case "ArrowDown":
                p.moveDown()
            break
            default:
            break
        }
    }

    //drop the piece every 250ms initially every 1000ms
    function drop() {
        if (isGameOn && !isPaused) {
            let now = Date.now()
            let elapsedTime = now - dropStart
            if(elapsedTime > speed) {
                p.moveDown()
                if (isGameOn && !isPaused) {
                    dropStart = Date.now()
                }
            }
            if (!gameOver) {
                animationFrameId = requestAnimationFrame(drop)
            }
        }
    }

    drop()


    //**********  ANIMATION GAMEOVER *********//
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    function clearBoardRandomlyWithDelay() {
        shuffleArray(board);
    
        let index = 0;
    
        function clearNextSquare() {
            if (index < ROW * COL) {
                const r = Math.floor(index / COL);
                const c = index % COL;
    
                // Set the square as VACANT
                board[r][c] = VACANT;
                
                // Redraw the cleared square
                drawSquare(c, r, VACANT);
    
                index++;
    
                // Use requestAnimationFrame for the next square
                requestAnimationFrame(clearNextSquare);
            } else {
                // When all squares are cleared, redraw the entire board
                drawBoard()
                start()
                drop()
            }
        }
    
        // Start clearing the squares
        clearNextSquare();
    }


    //********** GAME HANDLERS *********//
    const start = () => {
        init()
        // Reset game state variables
        score = 0
        speedIncrement = 100
        updateScore(score)
        gameOver = false
        p = null

        // Initialize the board and draw it
        updateBoard()
        drawBoard()

        // start the game
        p = randomPiece()
        dropStart = Date.now()
    }

    const pause = (gameOn, paused) => {
        isPaused = paused
        isGameOn = gameOn
        if (animationFrameId && isPaused) {
            cancelAnimationFrame(animationFrameId);
            animationFrameId = null;
            document.removeEventListener('keydown', CONTROLS)

        }
    }

    const resume = (gameOn, gridOn, paused) => {
        isPaused = paused
        isGameOn = gameOn
        isGridOn = gridOn
        
        if (isGameOn && !isPaused && animationFrameId === null ) {
            drawBoard()
            document.addEventListener('keydown', CONTROLS)
            dropStart = Date.now()
            drop()
        }
    }

    const stop = () => {
        if (animationFrameId) {
            cancelAnimationFrame(animationFrameId)
            animationFrameId = null
        }
        document.removeEventListener('keydown', CONTROLS)
        updateBoard()
        drawBoard()
    return
    }

    const drawGrid = (gridOn) => {
        isGridOn = gridOn
        cancelAnimationFrame(animationFrameId)
        drawBoard()
        dropStart = Date.now()
        drop()
    }
      
    return {
        start,
        pause,
        resume,
        stop,
        drawGrid
    }
}

export default tetris