import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import Tetris from '../layout/Tetris'
import TouchDeviceChecker from '../components/TouchDeviceChecker'
import ScreenWidth from '../components/ScreenWidth'
import ScrollButton from '../components/ScrollButton'

export default function Home() {
  const [ isTouchDevice, setIsTouchDevice ] = useState(false)
  const [ isSmallScreen, setIsSmallScreen ] = useState(false)
    
  useEffect(() => {

  },[isTouchDevice])

  const handleScroll = () => {
      window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth'})
    }

  const handleIsTouchDeviceChange = (isTouchDevice) => {
    setIsTouchDevice(isTouchDevice)
  }

  const handleWidthChange = (newWidth) => {
    if (newWidth <= 500) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  return (
    
    <>
      <ScreenWidth onWidthChange={handleWidthChange}/>
      <TouchDeviceChecker onIsTouchDeviceChange={handleIsTouchDeviceChange} />
      <section id='home_section'>
        <div className='noise_bg'></div>
        <Hero isSmallScreen={isSmallScreen}/>
        <Tetris touchDevice={isTouchDevice}/>
        {!isTouchDevice?
        // <a onClick={handleScroll}><ScrollButton /></a>
        <div></div>
        :
        null}
      </section>
    </>
  )
}
