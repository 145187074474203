export default function Hero({ isSmallScreen,  }) {
  return (
    <>
      <div id='hero_div'>
        { isSmallScreen? 
          <>
          <h1>Hi</h1>
          <h4>I'm <span id='myName'>Vincent</span>,</h4>
          <h4><span id='myJob'>Front-End dev</span>,</h4>
          <h4>from France.</h4>
          </>
          :
          <>
          <h1>Hello</h1>
          <h4>my name is <span id='myName'>Vincent</span>,</h4>
          <h4><span id='myJob'>Front-End developper</span>,</h4>
          <h4>based in Montreuil, France.</h4>
          </>
        }
      </div>
    </>
  )
}
