import { useEffect, useState } from 'react'

const ScreenWidth = ({ onWidthChange }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth
      setScreenWidth(newWidth)
      if (onWidthChange) {
        onWidthChange(newWidth)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [onWidthChange])

  return null
}

export default ScreenWidth
