import React from 'react'

export default function GameboyIcon({ isGameOn }) {
  return (
    <>
        {isGameOn ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="60" viewBox="0 0 44 60" fill="none">
              <path d="M2 0.999999C2 0.447714 2.44772 0 3 0H41C41.5523 0 42 0.447715 42 1V56C42 58.2091 40.2091 60 38 60H3C2.44771 60 2 59.5523 2 59V0.999999Z" fill="#FAFAFA"/>
              <path d="M6 5C6 4.44772 6.44772 4 7 4H37C37.5523 4 38 4.44772 38 5V23C38 25.7614 35.7614 28 33 28H7C6.44772 28 6 27.5523 6 27V5Z" fill="#8F8F9B"/>
              <rect x="6" y="38" width="12" height="4" fill="#202020"/>
              <rect x="10" y="34" width="4" height="12" fill="#202020"/>
              <rect x="26" y="40" width="6" height="6" rx="3" fill="#7C1D3F"/>
              <rect x="32" y="34" width="6" height="6" rx="3" fill="#7C1D3F"/>
              <rect x="3" y="13" width="2" height="2" rx="1" fill="#FF0000"/>
              <rect x="12" y="6" width="20" height="20" fill="#798200"/>
            </svg>
            :
            <svg className='gameboyOff' xmlns="http://www.w3.org/2000/svg" width="44" height="60" viewBox="0 0 44 60" fill="none">
              <path d="M2 0.999999C2 0.447714 2.44772 0 3 0H41C41.5523 0 42 0.447715 42 1V56C42 58.2091 40.2091 60 38 60H3C2.44771 60 2 59.5523 2 59V0.999999Z" fill="#FAFAFA"/>
              <path d="M6 5C6 4.44772 6.44772 4 7 4H37C37.5523 4 38 4.44772 38 5V23C38 25.7614 35.7614 28 33 28H7C6.44772 28 6 27.5523 6 27V5Z" fill="#8F8F9B"/>
              <rect x="12" y="6" width="20" height="20" fill="#202020"/>
              <rect x="6" y="38" width="12" height="4" fill="#202020"/>
              <rect x="10" y="34" width="4" height="12" fill="#202020"/>
              <rect x="26" y="40" width="6" height="6" rx="3" fill="#202020"/>
              <rect x="32" y="34" width="6" height="6" rx="3" fill="#202020"/>
            </svg>}
    </>
  )
}
