import React from 'react'

export default function About() {
  
  const handleScroll = () => {
    window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth'})
  }

  return (
    <section id='about_section'>
        about
        <svg onClick={handleScroll} id='scrollIcon' width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="45" height="45" rx="22.5" stroke="#FAFAFA" strokeWidth="2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M25 8C25 7.44772 24.5523 7 24 7C23.4477 7 23 7.44772 23 8V37.5858L8.70711 23.2929C8.31658 22.9024 7.68342 22.9024 7.29289 23.2929C6.90237 23.6834 6.90237 24.3166 7.29289 24.7071L23.2929 40.7071C23.6834 41.0976 24.3166 41.0976 24.7071 40.7071L40.7071 24.7071C41.0976 24.3166 41.0976 23.6834 40.7071 23.2929C40.3166 22.9024 39.6834 22.9024 39.2929 23.2929L25 37.5858V8Z" fill="#FAFAFA"/>
        </svg>
    </section>
  )
}
