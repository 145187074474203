import GameScore from './GameScore'
import SlideButton from './SlideButton'
import BasicButton from './BasicButton'

export default function GameUI({ score,  handleGrid, isGridOn, handleColor, isHardcore, handlePause, isPaused }) {
  return (
    <div id='gameUI'>
        
        <GameScore score={ score }/>

        <div id='gridBtnWrap'>
          <SlideButton click={handleGrid} state={isGridOn}/>
          <p>grid: {isGridOn? "ON  ": "OFF"}</p>
        </div>

        <div id='colorBtnWrap'>
          <SlideButton click={handleColor} state={isHardcore}/>
          <p>{isHardcore? 'hardcore💀' : 'joyful🦄'}</p>
        </div> 

        <BasicButton click={handlePause} name={isPaused? 'Resume' : 'Pause'}/>
      </div> 
  )
}
