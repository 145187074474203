import React from 'react'

export default function GameControls() {
  return (
    <div id='touchCTRL'>
          <div id='areaDirection'>
            <div id='areaLeft'>
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="52" viewBox="0 0 34 52" fill="none">
                <path d="M18.2426 48.2426L3.75736 33.7574C2.63214 32.6321 2 31.106 2 29.5147L2 22.4853C2 20.894 2.63215 19.3679 3.75736 18.2426L18.2426 3.75736C19.3679 2.63214 20.894 2 22.4853 2L26 2C29.3137 2 32 4.68629 32 8L32 44C32 47.3137 29.3137 50 26 50L22.4853 50C20.894 50 19.3679 49.3679 18.2426 48.2426Z" stroke="#FAFAFA" strokeWidth="1" strokeLinejoin="round"/>
              </svg>
            </div>
            <div id='areaRight'>
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="52" viewBox="0 0 34 52" fill="none">
                <path d="M15.7574 3.75736L30.2426 18.2426C31.3679 19.3679 32 20.894 32 22.4853L32 29.5147C32 31.106 31.3679 32.6321 30.2426 33.7574L15.7574 48.2426C14.6321 49.3679 13.106 50 11.5147 50L8 50C4.68629 50 2 47.3137 2 44L2 8C2 4.68629 4.68629 2 8 2L11.5147 2C13.106 2 14.6321 2.63214 15.7574 3.75736Z" stroke="#FAFAFA" strokeWidth="1" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
          <div id='areaActions'>
            <div id='areaRotate'>
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="48" viewBox="0 0 42 48" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.6464 3.64649C20.4512 3.84175 20.4512 4.15833 20.6464 4.3536L23.8284 7.53558C24.0237 7.73084 24.3403 7.73084 24.5355 7.53558C24.7308 7.34032 24.7308 7.02373 24.5355 6.82847L22.2071 4.50004H34C37.5898 4.50004 40.5 7.41019 40.5 11V37C40.5 40.5899 37.5898 43.5 34 43.5H29.5714C29.2953 43.5 29.0714 43.7239 29.0714 44C29.0714 44.2762 29.2953 44.5 29.5714 44.5H34C38.1421 44.5 41.5 41.1422 41.5 37V11C41.5 6.85791 38.1421 3.50004 34 3.50004H22.2071L24.5355 1.17162C24.7308 0.976355 24.7308 0.659772 24.5355 0.46451C24.3403 0.269248 24.0237 0.269248 23.8284 0.46451L20.6464 3.64649ZM21.3535 44.3535C21.5488 44.1583 21.5488 43.8417 21.3535 43.6464L18.1716 40.4644C17.9763 40.2692 17.6597 40.2692 17.4645 40.4644C17.2692 40.6597 17.2692 40.9763 17.4645 41.1716L19.7929 43.5H8C4.41015 43.5 1.5 40.5898 1.5 37L1.5 24L1.5 11C1.5 7.41014 4.41015 4.49999 8 4.49999H11C11.2761 4.49999 11.5 4.27613 11.5 3.99999C11.5 3.72385 11.2761 3.49999 11 3.49999H8C3.85786 3.49999 0.499999 6.85785 0.499999 11L0.5 24L0.500001 37C0.5 41.1421 3.85786 44.5 8 44.5H19.7929L17.4645 46.8284C17.2692 47.0237 17.2692 47.3403 17.4645 47.5355C17.6597 47.7308 17.9763 47.7308 18.1716 47.5355L21.3535 44.3535Z" fill="white"/>
              </svg>
            </div>
            <div id='areaDown'>
              <svg xmlns="http://www.w3.org/2000/svg" width="52" height="34" viewBox="0 0 52 34" fill="none">
                <path d="M48.2426 15.7574L33.7574 30.2426C32.6321 31.3679 31.106 32 29.5147 32L22.4853 32C20.894 32 19.3679 31.3679 18.2426 30.2426L3.75736 15.7574C2.63214 14.6321 2 13.106 2 11.5147L2 8C2 4.68629 4.68629 2 8 2L44 2C47.3137 2 50 4.68629 50 8L50 11.5147C50 13.106 49.3679 14.6321 48.2426 15.7574Z" stroke="#FAFAFA" strokeWidth="1" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </div> 
  )
}
