import { useEffect, useState, useRef } from 'react'
import tetris from '../utils/tetris_functions'
import ScreenWidth from '../components/ScreenWidth'
import GameboyIcon from '../components/GameboyIcon'
import GamepadIcon from '../components/GamepadIcon'
import GameControls from '../components/GameControls'
import GameUI from '../components/GameUI'

export default function Tetris(isTouchDevice) {
  const [ isGameOn, setIsGameOn ] = useState(false)
  const [ isGridOn, setIsGridOn ] = useState(false)
  const [ isPaused, setIsPaused ] = useState(false)
  const [ isResumed, setIsResumed ] = useState(false) 
  const [ isHardcore, setIsHardcore ] = useState(false)
  const [ score, setScore ] = useState(null)
  const [ isSmallScreen, setIsSmallScreen ] = useState(false)
  const tetrisGame = useRef(null)

  useEffect(() => {

    // handle GRID
    if (isGameOn && !isPaused && tetrisGame.current && !isResumed ) {
      tetrisGame.current.drawGrid(isGridOn)
    }

    // handle START
    if (isGameOn && !isPaused && !tetrisGame.current) {
      tetrisGame.current = tetris(isGameOn, isGridOn, isPaused, updateScore, isHardcore, isTouchDevice)
      tetrisGame.current.start()
    }

    // handle PAUSE
    if (isGameOn && isPaused && tetrisGame.current && !isResumed) {
      tetrisGame.current.pause(isGameOn, isPaused)
      setIsResumed(!isResumed)
    }

    // handle RESUME
    if (isGameOn && !isPaused && tetrisGame.current && isResumed) {
      tetrisGame.current.resume(isGameOn, isGridOn, isPaused)
      setIsResumed(!isResumed)
    }

    // handle STOP and cleanup
    if (!isGameOn && tetrisGame.current) {
      if (tetrisGame.current.stop) {
        tetrisGame.current.stop()
        setScore(0)
      }
      tetrisGame.current = null
    }
  }, [isGameOn, isGridOn, isPaused, isResumed, isHardcore, isTouchDevice])


  const handleStartGame = () => {   
    setIsGameOn(!isGameOn) //toggle
    if (!isGameOn) {
      setIsPaused(false)
    }
  }

  const handleGrid = () => {
    setIsGridOn(!isGridOn) //toggle
  }

  const handleColor = () => {
    setIsHardcore(!isHardcore) // toggle
  }

  const handlePause = () => {
    setIsPaused(!isPaused) // toggle
  }

  const updateScore = (newScore) => {
    const formattedScore = String(newScore).padStart(7, '0')
    setScore(formattedScore)
  }

  const handleWidthChange = (newWidth) => {
    if (newWidth <= 500) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  return (
    <>
      <ScreenWidth onWidthChange={handleWidthChange}/>

      {isGameOn? <>
        <canvas id='tetris_div' tabIndex={0}></canvas>
          {isTouchDevice? <GameControls /> : null} </>
        : 
        null}

      <div id='gameBtn'onClick={handleStartGame}>
        {isSmallScreen? <GamepadIcon isGameOn={isGameOn}/> : <GameboyIcon isGameOn={isGameOn}/>}
      </div>

      {isGameOn ? 
        <GameUI score={score} handleGrid={handleGrid} isGridOn={isGridOn} handleColor={handleColor} isHardcore={isHardcore} handlePause={handlePause} isPaused={isPaused}/>: null }
    </>
  )
}